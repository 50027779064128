<template>
  <div>
    <NuxtLayout class="text-purple">
      <NuxtPage />
    </NuxtLayout>

    <UNotifications class="z-[100]" />
    <HubBackToTopButton />
  </div>
</template>

<script setup lang="ts">
import 'primevue/resources/themes/lara-light-blue/theme.css'

useHead({
  titleTemplate: titleChunk => {
    return titleChunk ? `${titleChunk} - TruHub` : 'TruHub'
  }
})
</script>
